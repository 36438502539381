import React, { useState } from 'react';
import { auth, googleProvider } from './firebase';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup } from 'firebase/auth';
import './auth.css'; // Import the new CSS file

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user);
      console.log('Verification email sent to:', userCredential.user.email);
      alert('Verification email sent. Please check your inbox.');
      navigate('/login'); // Redirect to login after sign up
    } catch (error) {
      console.error('Error during email sign-up:', error.message);
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('Signed in with Google:', result.user.email);
      navigate('/dashboard'); // Redirect to dashboard after Google sign-up
    } catch (error) {
      console.error('Error during Google sign-up:', error.message);
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-title">Sign Up</h2>
      <form onSubmit={handleSignUp} className="auth-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="auth-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="auth-input"
        />
        <button type="submit" className="auth-button">Sign Up</button>
      </form>
      <button onClick={handleGoogleSignUp} className="auth-google-button">Sign Up with Google</button>
    </div>
  );
}

export default SignUp;
