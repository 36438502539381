import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';  // This uses the AuthContext you have already defined
import './StoryComponent.css';

const StoryComponent = () => {
  const [characterName, setCharacterName] = useState('');
  const [genre, setGenre] = useState('');
  const [story, setStory] = useState('');
  const [error, setError] = useState('');
  const { getIdToken } = useAuth();  // Get the ID token function from context

  const handleGenerateStory = async () => {
    try {
      const token = await getIdToken();  // Get Firebase ID Token
      if (!token) {
        throw new Error('User is not authenticated');
      }
  
      const response = await axios.post('https://us-central1-speedytales-74ebf.cloudfunctions.net/app/generate-story', {
        userInput: { characterName, genre }
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      setStory(response.data.storySegment);
      setError(''); // Clear previous errors
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // Show the "out of credits" message
        setError(err.response.data.message);
      } else {
        // Handle other errors
        setError('Error generating story');
      }
    }
  };
  
  return (
    <div>
      <h1>Story Generator</h1>
      <input
        type="text"
        placeholder="Character name(e.g.Zork,Misty,Steve)"
        value={characterName}
        onChange={(e) => setCharacterName(e.target.value)}
      />
      <select value={genre} onChange={(e) => setGenre(e.target.value)}>
      <option value="">Select genre</option>
      <option value="Adventure">Adventure</option>
      <option value="Fantasy">Fantasy</option>
      <option value="Detective">Detective</option>
      <option value="Science Fiction">Science Fiction</option>
      <option value="Fairy Tale">Fairy Tale</option>
      <option value="Animal Stories">Animal Stories</option>
      <option value="Superhero">Superhero</option>
      <option value="Underwater Adventures">Underwater Adventures</option>
      <option value="Pirates">Pirates</option>
      <option value="Spooky (non-scary)">Spooky (non-scary)</option>
      <option value="Historical Adventure">Historical Adventure</option>
      <option value="Space Exploration">Space Exploration</option>
      <option value="Dinosaurs">Dinosaurs</option>
      <option value="Sports">Sports</option>
      <option value="Friendship">Friendship</option>
      </select>
      <button className='storycompbutton' onClick={handleGenerateStory}>Generate Story</button>

      {error && <p style={{ color: 'red' }}>{error}</p>}
      {story && <p>{story}</p>}
    </div>
  );
};

export default StoryComponent;
