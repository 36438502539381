import React from "react";


function Pricing(){
    return (
        <div>
            hello pricing
        </div>
    )
}


export default Pricing;