import React, { createContext, useContext, useEffect, useState } from 'react';
import { auth } from './firebase.js';  // Ensure this points to the correct firebase.js
import { onAuthStateChanged } from 'firebase/auth';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const getIdToken = async () => {
    if (user) {
      return await user.getIdToken();
    }
    return null;
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator while the user state is being determined
  }

  return (
    <AuthContext.Provider value={{ user, getIdToken }}>
      {children}
    </AuthContext.Provider>
  );
};
