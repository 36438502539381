import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar.js';
import Home from './Home.js';
import Pricing from './Pricing.js';
import SignUp from './SignUp.js';
import Login from './Login.js';
import Dashboard from './Dashboard.js';
import Sidebar from './Sidebar.js';
import StoryComponent from './StoryComponent.js'; 
import PrivateRoute from './PrivateRoute.js';
import StoryPage from './StoryPage'; // Import the StoryPage component
import { AuthProvider, useAuth } from './AuthContext.js';
import './App.css';

const AppContent = () => {
  const { user } = useAuth();

  return (
    <div className={`app-container ${user ? 'logged-in' : 'not-logged-in'}`}>
      {user ? <Sidebar /> : <Navbar />}
      <div className={`content-container ${user ? 'logged-in' : ''}`}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/bot" element={<PrivateRoute><StoryComponent /></PrivateRoute>} />
          
          {/* Add the route for the StoryPage */}
          <Route path="/story/:id" element={<StoryPage />} /> {/* Route for individual stories */}
        </Routes>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
};

export default App;
