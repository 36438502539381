import React from 'react';
import './home.css';

function Home() {
  return (
    <div>
      {/* Image section */}
      <div className='home-container'>
        <div className='image-container'>
          <img src='home1.png' alt='Home' className='home-image' />
        </div>
      </div>
      
      {/* Introductory section */}
      <div>
        <h2 className='home-h2'>Unlock the Magic of Storytelling!</h2>
        <p className='p-h2'>
          Imagine your child at the heart of their very own adventure, guided by interactive and personalized stories. 
          It's a world where creativity meets learning, bringing endless fun and wonder to every tale!
        </p>
      </div>

      {/* Cards section */}
      <div className='cards-container'>
        <div className='card'>
          <h3>Personalized Learning</h3>
          <p>
            Tailored stories adapt to your child's interests and reading level, making learning engaging and effective. 
            Watch as your child’s curiosity grows with content that evolves with them.
          </p>
        </div>
        <div className='card'>
          <h3>Enhanced Creativity</h3>
          <p>
            Interactive narratives encourage kids to use their imagination and think creatively. 
            Your child becomes an active participant in the story, fostering a love for creative thinking and problem-solving.
          </p>
        </div>
        <div className='card'>
          <h3>Safe and Educational Content</h3>
          <p>
            Enjoy peace of mind knowing your child is engaging with age-appropriate, educational material. 
            AI ensures that the stories are not only entertaining but also enriching and safe.
          </p>
        </div>
        <div className='card'>
          <h3>Convenient and Time-Saving</h3>
          <p>
            Easily accessible anytime, anywhere, these stories provide a perfect solution for busy parents. 
            Whether it’s bedtime or a quiet afternoon, the app delivers quality storytelling without the need for constant parental oversight.
          </p>
        </div>
      </div>

      {/* Additional sections */}
      <div className='home-section'>
      <h1 className='step-h1'>What to expect after log-in?</h1>
        <h2 className='home-h2'>Step:1 Sign Up</h2>
        <h2 className='home-h2'>Step into the Adventure: Sign Up Now!</h2>
        <p className='p-h2'>
          To get started, simply sign up(it's free) by creating an account. It’s quick and easy, and once you’re signed up, you’ll have access to everything our platform has to offer.
        </p>
      </div>

      <div className='home-section'>
        <h2 className='home-h2'>Step:2 Explore Our Library</h2>
        <h2 className='home-h2'>Discover a World of Stories: Read, Watch, and Play!</h2>
        <p className='p-h2'>
          After signing up, you'll find a library full of exciting stories. You can read any of the stories, watch a video version of the same story, or even play a fun game based on that story.
          It's a complete interactive experience that lets you enjoy each story in multiple ways!
        </p>
      </div>

      <div className='home-section'>
        <h2 className='home-h2'>Step:3 Meet Speedy the Bot</h2>
        <h2 className='home-h2'>Create Your Own Tale with Speedy the Storyteller!</h2>
        <p className='p-h2'>
          In addition to the library, you'll meet Speedy the Bot, our AI-based story generator. With Speedy, you can create your own unique stories! 
          You get to decide the character’s name and choose from 12 different genres like adventure, mystery, or fantasy. 
          And don’t worry, even more genres are on the way, so there will always be something new to explore!
        </p>
      </div>

      <footer className='footer'>
        <p className='footer-p'>
          © {new Date().getFullYear()} SpeedyTales(a product by VirtuStratum Technology Solutions LLP)| 
          <a href='mailto:suryakumar@speedytales.com'> suryakumar@speedytales.com</a> | WhatsApp: <a href='https://wa.me/+919361735172' target='_blank' rel='noopener noreferrer'>+919361735172</a>
        </p>
      </footer>
    </div>
  );
}

export default Home;
