import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import stories from './stories'; 
import './storypage.css';

function StoryPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const story = stories.find(story => story.id === parseInt(id));

  if (!story) {
    return <div>Story not found</div>;
  }

  const formatDescription = (description) => {
    return description.split('\n\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  return (
    <div className='story-page-container'>
      <div className='story-card'>
        <button className='back-button' onClick={() => navigate('/dashboard')}>Back to Dashboard</button>

        {/* Video and Game Links at the top */}
        <div className='links-container'>
          {/* Video Link */}
          {story.videoLink && (
            <a href={story.videoLink} target="_blank" rel="noopener noreferrer" className='video-link'>
              Watch Story Video
            </a>
          )}

          {/* Game Link */}
          {story.gameLink && (
            <a href={story.gameLink} target="_blank" rel="noopener noreferrer" className='game-link'>
              Play the Game
            </a>
          )}
        </div>

        <img src={story.imagein} alt={story.title} className='story-image' />
        <h2>{story.title}</h2>
        <div className="p-story">
          {formatDescription(story.description)}
        </div>
      </div>
    </div>
  );
}

export default StoryPage;
