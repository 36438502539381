// src/Dashboard.js
import React from 'react';
import './Sidebar.js';
import ThumbnailSlider from './ThumbnailSlider';

function Dashboard() {
  return (
    <div>
      <ThumbnailSlider />
    </div>
  );
}

export default Dashboard;
