import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, googleProvider } from './firebase.js';
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import './auth.css'; // Import the new CSS file

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Logged in with email:', userCredential.user.email);
      navigate('/dashboard'); // Redirect to dashboard after login
    } catch (error) {
      console.error('Error during email login:', error.message);
    }
  };

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log('Logged in with Google:', result.user.email);
      navigate('/dashboard'); // Redirect to dashboard after Google login
    } catch (error) {
      console.error('Error during Google login:', error.message);
    }
  };

  return (
    <div className="auth-container">
      <h2 className="auth-title">Login</h2>
      <form onSubmit={handleLogin} className="auth-form">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="auth-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="auth-input"
        />
        <button type="submit" className="auth-button">Login</button>
      </form>
      <button onClick={handleGoogleLogin} className="auth-google-button">Login with Google</button>
    </div>
  );
}

export default Login;
