import React from 'react';
import { Link } from 'react-router-dom';
import './ThumbnailSlider.css';
import stories from './stories.js'; // Import the stories data

function ThumbnailSlider() {
  return (
    <div className='thumbnail-card-container'>
      <h2>Featured Stories</h2>
      <div className='thumbnail-card-grid'>
        {stories.map((story) => (
          <div key={story.id} className='thumbnail-card'>
            <Link to={`/story/${story.id}`}>
              <div className='thumbnail-image-container'>
                <img src={story.imageout} alt={story.title} className='thumbnail-image' />
              </div>
              <h4 className='thumbnail-title'>{story.title}</h4>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ThumbnailSlider;
