import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import './sidebar.css';

function Sidebar() {
  const [user] = useAuthState(auth);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {user && (
        <div>
          {/* Hamburger icon for small screens */}
          <div className="hamburger-menu" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>

          {/* Sidebar visible on larger screens, or dropdown menu on small screens */}
          <div className={`sidebar-container ${isMenuOpen ? 'open' : ''}`}>

          <img src="/logo.png" alt="Logo" className="sidebar-logo" />

            <h3 className='sidebar-title'>Welcome, {user.email}</h3>
            <ul className='sidebar-links'>
              <li>
                <Link to="/dashboard">Library</Link>
              </li>
              <li>
                <Link to="/bot">Speedy the Bot</Link>
              </li>
              <li>
                <button onClick={() => auth.signOut()} className="sidebar-logout-button">Logout</button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default Sidebar;
