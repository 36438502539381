import React from 'react';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase.js';
import './navbar.css';

function Navbar() {
  const [user] = useAuthState(auth);

  return (
    <div>
      <nav className='nav-container'>
        <div className='logo-container'>
          <img src='logo.png' alt='Logo' className='logo' />
          <h2 className='pname'>Speedy Tales</h2>
        </div>
        <div className='tagname'><h6>Unlock Lil' Ones Creativity</h6></div>
        <ul className='nav-links'>
          <li>
            <Link to="/">Home</Link>
          </li>
          {!user && (
            <>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/signup">Sign Up/Free</Link>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
